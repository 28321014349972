import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToolbarOptionsService } from '../../services/toolbar-options.service';
import { Observable } from 'rxjs';
import { ToolbarOption } from '../../models/toolbar-option';
import { SidenavData } from '../../models/sidenav-data';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Output() toggleSidenav = new EventEmitter();

  data: Observable<SidenavData>;

  lang: string;

  constructor(private optionsService: ToolbarOptionsService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.optionsService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.optionsService.loadOptions(this.lang, "toolbar_options");
  }

}
