<section>
    <h2>{{ (data | async).SeccionInicial.Titulo }}</h2>
    <p>{{ (data | async).SeccionInicial.Cuerpo }}</p>
</section>

<section [@banner]="{value: bannerState}" (@banner.done)="onBannerDone($event)">
    <img class="banner" src="./assets/banner1.png" alt="banner">
    <img class="banner" src="./assets/banner2.png" alt="banner">
    <img class="banner" src="./assets/banner3.png" alt="banner">
    <img class="banner" src="./assets/banner4.png" alt="banner">
    <img class="banner" src="./assets/banner5.png" alt="banner">
</section>

<section class="ss-secundaria">
    <h3>{{ (data | async).SeccionSecundaria }}</h3>
</section>

<section>
    <h3> {{ (data | async).SeccionOpciones }}</h3>
</section>

<div class="card-content">
    <mat-card class="card-item mat-elevation-z5" *ngFor="let opcion of (data | async).Opciones">
        <h4>{{ opcion.Titulo }}</h4>
        <p>{{ opcion.Cuerpo }}</p>
        <button mat-flat-button color="accent" [routerLink]="['/', opcion.Link]">Más Información</button>
    </mat-card>
</div>

<section>
    <h3>{{ (data | async)?.SeccionServicios }}</h3>
</section>

<div class="card-content">
    <mat-card class="card-servicio-item mat-elevation-z5" *ngFor="let servicio of (data | async).Servicios">
        <img class="icon" [src]="servicio.Imagen" [alt]="servicio.Titulo">
        <div>
            <h4>{{ servicio.Titulo }}</h4>
            <button mat-flat-button color="accent" [routerLink]="['/', 'servicios', servicio.Link]">Más Información</button>
        </div>
    </mat-card>
</div>

<section>
    <h3>{{ (data | async).SeccionHitos }}</h3>
</section>

<div class="card-content" [@hitos]="{value: hitosState}" (@hitos.done)="onHitosDone($event)">
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito1 }}</p>
    </mat-card>
    <mat-icon color="accent" class="card-arrow">double_arrow</mat-icon>
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito2 }}</p>
    </mat-card>
    <mat-icon color="accent" class="card-arrow">double_arrow</mat-icon>
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito3 }}</p>
    </mat-card>
    <mat-icon color="accent" class="card-arrow">double_arrow</mat-icon>
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito4 }}</p>
    </mat-card>
    <mat-icon color="accent" class="card-arrow">double_arrow</mat-icon>
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito5 }}</p>
    </mat-card>
    <mat-icon color="accent" class="card-arrow">double_arrow</mat-icon>
    <mat-card class="hito-item mat-elevation-z5">
        <p>{{ (data | async).Hito6 }}</p>
    </mat-card>
</div>