import { Component, OnInit } from '@angular/core';
import { slideProyectosAnimation } from '../../animations/animations';
import { Observable } from 'rxjs';
import { ProyectoPage } from '../../models/proyecto-page';
import { ProyectosService } from '../../services/proyectos.service';
import { RouterOutlet, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss'],
  animations: [ slideProyectosAnimation ]
})
export class ProyectosComponent implements OnInit {

  data: Observable<ProyectoPage>;

  lang: string;

  constructor(private proyectosService: ProyectosService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.proyectosService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.proyectosService.loadOptions(this.lang, "proyectos_options");      
  }

  prepareRoute(outlet: RouterOutlet) {
    
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
