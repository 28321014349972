import { Component, OnInit } from '@angular/core';
import { ServicioOption } from '../../models/servicio-option';
import { Observable } from 'rxjs';
import { ServiciosService } from '../../services/servicios.service';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { slideServiciosAnimation } from '../../animations/animations';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
  animations: [ slideServiciosAnimation ]
})
export class ServiciosComponent implements OnInit {

  data: Observable<ServicioOption>;

  lang: string;

  constructor(private servicioService: ServiciosService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.data = this.servicioService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.servicioService.loadOptions(this.lang, "servicios_options");  
  }

  prepareRoute(outlet: RouterOutlet) {
    
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
