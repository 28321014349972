import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AcercaDePage } from '../../models/acerca-de-page';
import { AcercaDeService } from '../../services/acerca-de.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss']
})
export class AcercaDeComponent implements OnInit {

  data: Observable<AcercaDePage>;

  lang: string;

  constructor(private proyectosService: AcercaDeService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.proyectosService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.proyectosService.loadOptions(this.lang, "acerca_de_page");      
  }
}
