import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ServicioPage } from '../../models/servicio-page';
import { ServiciosService } from '../../services/servicios.service';
import { RelacionesService } from '../../services/relaciones.service';
import { RelacionPage } from '../../models/relacion-page';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-relaciones',
  templateUrl: './relaciones.component.html',
  styleUrls: ['./relaciones.component.scss']
})
export class RelacionesComponent implements OnInit {

  data: Observable<RelacionPage>;

  lang: string;

  constructor(private proyectosService: RelacionesService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.proyectosService.data;
    
    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.proyectosService.loadOptions(this.lang, "relaciones_page");      
  }
}
