import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { MainAppComponent } from './main-app.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';

import { MaterialModule } from '../shared/material.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { RelacionesComponent } from './components/relaciones/relaciones.component';
import { AcercaDeComponent } from './components/acerca-de/acerca-de.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ToolbarOptionsService } from './services/toolbar-options.service';
import { ServicioItemComponent } from './components/servicio-item/servicio-item.component';
import { ProyectoItemComponent } from './components/proyecto-item/proyecto-item.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';

const routes: Routes = [
  { path: '', component: MainAppComponent,
    children: [
      { path: 'servicios', component: ServiciosComponent,
        children: [
          { path: 'urbanizaciones', component: ServicioItemComponent, data: {animation: 'urbanizaciones', origin: 'urbanizaciones_page'} },
          { path: 'instalaciones', component: ServicioItemComponent, data: {animation: 'instalaciones', origin: 'instalaciones_page'} },
          { path: 'edificacion', component: ServicioItemComponent, data: {animation: 'edificacion', origin: 'edificacion_page'} },
          { path: 'rehabilitacion', component: ServicioItemComponent, data: {animation: 'rehabilitacion', origin: 'rehabilitacion_page' } },
          { path: 'deportivos', component: ServicioItemComponent, data: {animation: 'deportivos', origin: 'deportivos_page'} },
          { path: 'comerciales', component: ServicioItemComponent, data: {animation: 'comerciales', origin: 'comerciales_page'} },
          { path: 'inicio', component: ServicioItemComponent, data: {origin: 'servicios_page'} },
          { path: '', component: ServicioItemComponent, data: {origin: 'servicios_page'} }
        ]
      },
      { path: 'proyectos', component: ProyectosComponent,
        children: [
          { path: 'piscina', component: ProyectoItemComponent, data: {animation: 'piscina', origin: 'proyecto_piscina_page'} },
          { path: 'deportivo', component: ProyectoItemComponent, data: {animation: 'deportivo', origin: 'proyecto_deportivo_page'} },
          { path: 'comercial', component: ProyectoItemComponent, data: {animation: 'comercial', origin: 'proyecto_comercial_page'} },
          { path: 'inicio', component: ProyectoItemComponent, data: {origin: 'proyectos_page'} },
          { path: '', component: ProyectoItemComponent, data: {origin: 'proyectos_page'} }
        ] 
      },
      { path: 'relaciones', component: RelacionesComponent },
      { path: 'acerca-de', component: AcercaDeComponent },
      { path: 'contacto', component: ContactoComponent },
      { path: '', component: MainContentComponent }      
    ] },
  { path: "**", redirectTo: 'home'}
];

@NgModule({
  declarations: [MainAppComponent, ToolbarComponent, MainContentComponent, SidenavComponent, ServiciosComponent, ProyectosComponent, RelacionesComponent, AcercaDeComponent, ContactoComponent, ServicioItemComponent, ProyectoItemComponent, MessageDialogComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBlIuXNU_45i_yjud3R7mPP4tXFiDxG3PM',
      libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a premium customer, in which case you can use clientId */
  }),
    RouterModule.forChild(routes)
  ],
  providers: [
    ToolbarOptionsService
  ],
  entryComponents: [
    MessageDialogComponent
  ]
})
export class MainModule { }
