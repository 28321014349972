import { Injectable } from '@angular/core';
import { AcercaDePage } from '../models/acerca-de-page';
import { PageService } from './page.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AcercaDeService extends PageService<AcercaDePage> {

  constructor(http: HttpClient) {
    super(http);
  }
}
