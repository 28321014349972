import { Injectable } from '@angular/core';
import { PageService } from './page.service';
import { HttpClient } from '@angular/common/http';
import { RelacionPage } from '../models/relacion-page';

@Injectable({
  providedIn: 'root'
})
export class RelacionesService extends PageService<RelacionPage> {

  constructor(http: HttpClient) {
    super(http);
  }
}
