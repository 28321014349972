import { Component, OnInit, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { ToolbarOption } from '../../models/toolbar-option';
import { ToolbarOptionsService } from '../../services/toolbar-options.service';
import { SidenavData } from '../../models/sidenav-data';
import { Router, ActivatedRoute } from '@angular/router';

const SMALL_WIDTH_BREAKPOINT = 720;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  
  data: Observable<SidenavData>;

  lang: string;

  constructor(zone: NgZone, private optionsService: ToolbarOptionsService, private route: ActivatedRoute, private router: Router) {
    this.mediaMatcher.addListener((mql) => 
      zone.run(() => this.mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)));      
   }

  ngOnInit(): void {
    this.data = this.optionsService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.optionsService.loadOptions(this.lang, "toolbar_options");
  }

  isScreenSmall() : boolean {
    return this.mediaMatcher.matches;
  }
}
