import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactoPage } from '../../models/contacto-page';
import { ContactoService } from '../../services/contacto.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { User } from '../../models/user';
import { FormControl, Validators } from '@angular/forms';
import { MailService } from '../../services/mail.service';
import { Location } from '../../models/Location';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})

export class ContactoComponent implements OnInit {

  data: Observable<ContactoPage>;
  user: User;
  contactoPage: ContactoPage;

  nombreControl = new FormControl('', [Validators.required]);

  location: Location;

  lang: string;

  constructor(private proyectosService: ContactoService, private dialog: MatDialog, 
              private mailService: MailService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.proyectosService.data;

    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.proyectosService.loadOptions(this.lang, "contacto_page");

    this.user = new User();

    this.data.subscribe(contacto => {
      this.contactoPage = contacto;
    });

    this.location = {
      latitude: 37.00217852913484,
      longitude: -4.368688038902392,
      mapType: "satelite",
      zoom: 15,
      marker: {
        lat: 37.00217852913484,
        lng: -4.368688038902392
    }
    }
  }

  submitContact(): void {

    this.mailService.sendEmail(this.user);

    let dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '450px',
      data: { lang: this.lang}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.user = new User();
    })
  }

  getNombreErrorMessage() {
    return this.nombreControl.hasError('required') ? this.contactoPage.NombreError : '';
  }
}
