import { Injectable } from '@angular/core';
import { PageService } from './page.service';
import { ServicioPage } from '../models/servicio-page';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiciosItemService extends PageService<ServicioPage> {

  constructor(http: HttpClient) {
    super(http);
  }
}
