import { trigger, style, transition, animate, state, AnimationEvent, query, stagger, animateChild, group } from '@angular/animations';
import { setClassMetadata } from '@angular/core/src/r3_symbols';

export const bannerAnimation = trigger('banner', [
  transition('void => start', [
    query('img', [
      style({ transform: 'translateX(-100%)', opacity: 0 }),
      stagger("1s", [
        animate('1s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ])
    ], { optional: true })
  ]),
  transition('loop => start', [
    query('img', [
      style({ transform: 'translateX(-100%)', opacity: 0 }),
      stagger("1s", [
        animate('1s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ])
    ], { optional: true })
  ]),
  transition('start => loop', [
    query('img', [
      style({ transform: 'translateX(0%)', opacity: 1 }),
      stagger("1s", [
        animate('1s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ], { optional: true })
  ]),
]);

export const hitosAnimation = trigger('hitos', [
  transition('void => start', [
    query('.hito-item, .card-arrow', [
      style({ opacity: 0 }),
      stagger("2s", [
        animate('1s ease-in-out', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ]),
  transition('loop => start', [
    query('.hito-item, .card-arrow', [
      style({ opacity: 0 }),
      stagger("2s", [
        animate('1s ease-in-out', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ]),
  transition('start => loop', [
    query('.hito-item, .card-arrow', [
      style({ opacity: 1 }),
      stagger("2s", [
        animate('1s ease-in-out', style({ opacity: 0 }))
      ])
    ], { optional: true })
  ]),
]);

export const slideServiciosAnimation =
  trigger('routeAnimations', [
    transition('* <=> urbanizaciones', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 1
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),     
    transition('* => instalaciones', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => edificacion', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => rehabilitacion', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => deportivos', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => comerciales', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);

  export const slideProyectosAnimation =
  trigger('routeAnimations', [
    transition('* <=> piscina', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 1
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => deportivo', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* => comercial', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', opacity: 0
        })
      ]),
      query(':enter', [
        style({ left: '-100%', opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%', opacity: 0}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%', opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);