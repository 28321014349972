import { Component, OnInit } from '@angular/core';
import { ServicioPage } from '../../models/servicio-page';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ServiciosItemService } from '../../services/servicios-item.service';

@Component({
  selector: 'app-servicio-item',
  templateUrl: './servicio-item.component.html',
  styleUrls: ['./servicio-item.component.scss']
})
export class ServicioItemComponent implements OnInit {

  data: Observable<ServicioPage>;
  lang: string;
  originData: string;

  constructor(private serviciosService: ServiciosItemService, private route: ActivatedRoute) {
    this.lang = "es";
    this.originData = route.snapshot.data['origin'];
  }

  ngOnInit(): void {
    this.data = this.serviciosService.data;
    
    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.serviciosService.loadOptions(this.lang, this.originData);
  }

}
