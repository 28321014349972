<mat-card>
    <section>
        <h2>{{ (data | async)?.Titulo }}</h2>
            <section *ngFor="let linea of (data | async).Cuerpo">
                <h3>{{linea.Titulo}}</h3>
                <p>{{linea.Cuerpo}}</p>
                <ul>
                    <li *ngFor="let item of linea.Lista">{{item}}</li>
                </ul>
            </section>
    </section>
</mat-card>