<mat-toolbar color="primary">
    <button mat-button class="sidenav-toggle" (click)="toggleSidenav.emit()" >
        <mat-icon>menu</mat-icon>
    </button>
    <img class="logo mat-elevation-z10" src="./assets/Logo.png" alt="">
    <div class="options-bar">
        <button *ngFor="let option of (data | async).Options" mat-flat-button class="toolbar-item" color="primary" 
                [routerLink]="['/', option.Link]"> 
            {{ option.Titulo }}
        </button>
    </div>    
    <mat-menu #appMenu="matMenu">
        <button mat-menu-item><img src="./assets/es.png" alt=""><span style="margin-left: 5px;">Español</span></button>
        <button mat-menu-item><img src="./assets/en.png" alt=""><span style="margin-left: 5px;">Ingles</span></button>
        <button mat-menu-item><img src="./assets/de.png" alt=""><span style="margin-left: 5px;">Aleman</span></button>
      </mat-menu>
      
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <img src="./assets/es.png" alt="">
      </button>
</mat-toolbar>
