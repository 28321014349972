import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export abstract class PageService<T> {

  private _data: BehaviorSubject<T>;

  private dataStore: {
    data: T
  }

  constructor(private http: HttpClient) {
    this.dataStore = { data: {} as T};
    this._data = new BehaviorSubject<T>({} as T);
   }
   
  get data(): Observable<T> {
    return this._data.asObservable();
  }

  loadOptions(language: string, page: string) {

    if(language === undefined){
      language = 'es';
    }
    
    var url = `assets/${language}/${page}.json`;

    return this.http.get<T>(url)
      .subscribe(data => {
        this.dataStore.data = data;
        this._data.next(Object.assign({}, this.dataStore).data);
      }, error => {
        console.log("Error cargando fichero");
      });
  }
}
