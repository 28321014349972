<mat-sidenav-container
    class="app-sidenav-container">
  <mat-sidenav #sidenav class="app-sidenav mat-elevation-z10"
                [mode]="isScreenSmall() ? 'over' : 'side'">
    <mat-toolbar color="primary">
      <img class="logo" src="./assets/Logo.png" alt="">
    </mat-toolbar>
    <mat-list role="list">
      <mat-nav-list>
        <mat-list-item *ngFor="let option of (data | async).Options">
          <a matLine [routerLink]="['/', option.Link]">{{ option.Titulo }}</a>
          <button mat-icon-button>
          </button>
       </mat-list-item>       
      </mat-nav-list>
      </mat-list>      
  </mat-sidenav>

  <mat-sidenav-content class="app-sidenav-content">
    <app-toolbar (toggleSidenav)="sidenav.toggle()"></app-toolbar>
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>  
    <app-footer fxFlexOffset="auto" class="app-sidenav-footer">
      <mat-toolbar color="primary">
        <h3 class="text-footer" >{{ (data | async)?.Banner }}</h3>
      </mat-toolbar>      
    </app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>