import { Injectable } from '@angular/core';
import { User } from '../models/user';
import emailjs from 'emailjs-com';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor() { }

  sendEmail(user: User) {
    
    var templateParams = {
      nombre: user.Nombre,
      mail: user.Mail,
      telefono: user.Telefono,
      mensaje: user.Mensaje
    };

    emailjs.send('alcala_godoy_server', 'contato', templateParams, 'user_JlMgqUgBiPrbw5y2BiniJ')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function (err) {
        console.log('FAILED...', err);
      });
  }
}
