<h2>{{ (data | async)?.Titulo }}</h2>
<div></div>
<div class="contacto-container">
    <div>
        <p>{{ (data | async)?.Cabecera }}</p>
        <ul>
            <li>{{ (data | async)?.Telefono }}</li>
            <li>{{ (data | async)?.Movil }}</li>
            <li>{{ (data | async)?.EtiquetaMail }} <a href="mailto:info@alcalagodoy.com">{{ (data | async)?.Mail }}</a></li>
        </ul>
        <p>{{ (data | async)?.Pie }}</p>
        <agm-map [latitude]="location.latitude" [longitude]="location.longitude" 
            [zoom]="location.zoom">
            <agm-marker [latitude]="location.marker.lat" [longitude]="location.marker.lng"></agm-marker>
        </agm-map>
    </div>
    <div class="formulario">
        <h4>{{ (data | async)?.TituloFormulario }}</h4>

        <mat-form-field>
            <input matInput [placeholder]="(data | async)?.NombreFormulario" [(ngModel)]="user.Nombre" required>
            <mat-error *ngIf="nombreControl.invalid">{{ getNombreErrorMessage() }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput [placeholder]="(data | async)?.MailFormulario" [(ngModel)]="user.Mail">            
        </mat-form-field>

        <mat-form-field>
            <input matInput [placeholder]="(data | async)?.TelefonoFormulario" [(ngModel)]="user.Telefono">
        </mat-form-field>

        <mat-form-field>
            <textarea matInput [placeholder]="(data | async)?.MensajeFormulario" 
            [(ngModel)]="user.Mensaje" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="20"></textarea>
        </mat-form-field>

        <button mat-flat-button color="accent" (click)="submitContact()" [disabled]="!user.Nombre">
            {{ (data | async)?.EnviarFormulario }}
        </button>
    </div>
</div>