<section>
    <h2>{{ (data | async).Titulo }}</h2>
</section>
<div *ngFor="let relacion of (data | async).Cuerpo">
    <h3>{{ relacion.Titulo }}</h3>
        <p>{{relacion.Cuerpo}}</p>
        <ul>
            <li *ngFor="let linea of relacion.Lineas">{{ linea }}</li>
        </ul>
        <div class="image-container">
            <div class="image-item" *ngFor="let imagen of relacion.Imagenes">
                <h4>{{ imagen.Titulo }}</h4>
                <img class="icon" [src]="imagen.Imagen" [alt]="imagen.Nombre">
                <h4>{{ imagen.Subtitulo }}</h4>
            </div>
        </div>
</div>