import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProyectoItem } from '../../models/proyecto-item';
import { ProyectosItemService } from '../../services/proyectos-item.service';
import { ActivatedRoute } from '@angular/router';
import { ProyectosService } from '../../services/proyectos.service';

@Component({
  selector: 'app-proyecto-item',
  templateUrl: './proyecto-item.component.html',
  styleUrls: ['./proyecto-item.component.scss']
})
export class ProyectoItemComponent implements OnInit {

  data: Observable<ProyectoItem>;
  lang: string;
  originData: string;

  constructor(private proyectosService: ProyectosItemService, private route: ActivatedRoute) {
    this.lang = "es";
    
    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.originData = route.snapshot.data['origin'];
  }

  ngOnInit(): void {
    this.data = this.proyectosService.data;
    this.proyectosService.loadOptions(this.lang, this.originData);
  }
}
