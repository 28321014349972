<section>
    <h2>{{ (data | async).Titulo }}</h2>
    <p>{{ (data | async).Seccion }}</p>
</section>
<div [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
</div>
<div class="proyectos-slide">
    <div class="proyecto-item" *ngFor="let proyecto of (data | async).Proyectos" [routerLink]="['/', 'proyectos', proyecto.Link]">
        <img class="icon" [src]="proyecto.Imagen" [alt]="proyecto.Titulo">
        <h4>{{ proyecto.Titulo }}</h4>        
    </div>
</div>
