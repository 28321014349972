import { Injectable } from '@angular/core';
import { ServicioOption } from '../models/servicio-option';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService extends PageService<ServicioOption> {

  constructor(http: HttpClient) {
    super(http);
  }
}
