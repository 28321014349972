import { Component, OnInit } from '@angular/core';
import { InicioPage } from '../../models/inicio-page';
import { Observable } from 'rxjs';
import { MainContentService } from '../../services/main-content.service';

import { trigger, style, transition, animate, state, AnimationEvent, query, stagger } from '@angular/animations';
import { bannerAnimation, hitosAnimation } from '../../animations/animations';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
  animations: [ bannerAnimation, hitosAnimation ]
})
export class MainContentComponent implements OnInit {

  data: Observable<InicioPage>;

  lang : string;

  bannerState = 'start';
  hitosState = 'start';
  isLoop = true;  

  constructor(private mainService: MainContentService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.data = this.mainService.data;
    
    this.lang = "es";

    if (this.route.snapshot.paramMap.has("lang")) {
      this.lang = this.route.snapshot.paramMap.get("lang");
    }

    this.mainService.loadOptions(this.lang, "inicio_page");    
  }

  onBannerDone(event: AnimationEvent): void {
    
    setTimeout(() => {
        this.bannerState === 'start' ? this.bannerState = 'loop' : this.bannerState = 'start';
      }, 0);
  }

  onHitosDone(event: AnimationEvent): void {
    
    setTimeout(() => {
        this.hitosState === 'start' ? this.hitosState = 'loop' : this.hitosState = 'start';
      }, 0);
  }
}
