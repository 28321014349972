import { Injectable } from '@angular/core';
import { PageService } from './page.service';
import { ProyectoPage } from '../models/proyecto-page';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService extends PageService<ProyectoPage> {

  constructor(http: HttpClient) {
    super(http);
  }
}
